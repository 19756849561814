<template>
    <tr>
        <td style="width: 5%"><button class="btn btn-sm btn-danger" @click="$emit('onClose', index)">X</button></td>
        <td style="width: 20%">{{condition.user_name}}</td>
        <td class="w-25">
            <div class="mb-2">
                <v-select
                    placeholder="Select amount condition type"
                    v-model="condition.amount.type"
                    :options="amountConditionTypes"
                    label="name"
                    :reduce="name => name.id"
                />
            </div>
            <div class="row">
                <div class="col-md-6" v-if="['over_or_equal_to','between'].includes(condition.amount.type)">
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Minimum Amount"
                        min="0"
                        v-model="condition.amount.min_amount"
                    >
                </div>
                <div class="col-md-6" v-if="['under','between'].includes(condition.amount.type)">
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Maximum Amount"
                        min="0"
                        v-model="condition.amount.max_amount"
                    >
                </div>
            </div>
        </td>
        <td class="w-25">
            <div class="mb-2">
                <v-select
                    placeholder="Select amount condition type"
                    v-model="condition.creator.type"
                    :options="conditionTypes"
                    label="name"
                    :reduce="name => name.id"
                />
            </div>
            <div class="mb-2" v-if="condition.creator.type !== 'any'">
                <v-select
                    placeholder="Select amount condition type"
                    v-model="condition.creator.creator_ids"
                    :multiple="true"
                    :options="users"
                    label="user_name"
                    :reduce="user_name => user_name.id"
                />
            </div>
        </td>
        <td class="w-25">
            <div class="mb-2">
                <v-select
                    placeholder="Select amount condition type"
                    v-model="condition.project.type"
                    :options="conditionTypes"
                    label="name"
                    :reduce="name => name.id"
                />
            </div>
            <div class="mb-2" v-if="condition.project.type !== 'any'">
                <v-select
                    placeholder="Select amount condition type"
                    v-model="condition.project.project_ids"
                    :multiple="true"
                    :options="projects"
                    label="name"
                    :reduce="name => name.id"
                />
            </div>
        </td>
    </tr>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";

    const $props = defineProps({
        users: {
            type: Object,
            required: true
        },
        projects: {
            type: Object,
            required: true
        },
        condition: {
            type: Object,
            required: true
        },
        amountConditionTypes: {
            type: Object,
            required: true
        },
        conditionTypes: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    });

    //computed
    const amountType = computed(() => $props.condition.amount.type)
    const minAmount = computed(() => $props.condition.amount.min_amount)
    const maxAmount = computed(() => $props.condition.amount.max_amount)
    const creatorType = computed(() => $props.condition.creator.type)
    const creatorIds = computed(() => $props.condition.creator.creator_ids)
    const projectType = computed(() => $props.condition.project.type)
    const projectIds = computed(() => $props.condition.project.project_ids)

    //methods
    const getCreatorNames = (ids) => {
        let names = '';
        let idCount = ids.length;
        let itemCount = 0;
        $props.users.forEach((item) => {
            if (ids.includes(item.id)) {
                names += item.user_name;
                if(++itemCount < idCount){
                    names += ', ';
                }
            }
        })
        return names;
    }

    const getProjectNames = (ids) => {
        let names = '';
        let idCount = ids.length;
        let itemCount = 0;
        $props.projects.forEach((item) => {
            if (ids.includes(item.id)) {
                names += item.name;
                if(++itemCount < idCount){
                    names += ', ';
                }
            }
        })
        return names;
    }

    const updateConditionText = () => {
        let conditionText = '';

        if($props.condition.amount.min_amount_text !== ''){
            conditionText = $props.condition.amount.min_amount_text;
        }
        if($props.condition.amount.max_amount_text !== ''){
            conditionText += ((conditionText !== '') ? ' and' : '') + $props.condition.amount.max_amount_text;
        }
        if($props.condition.creator.creator_ids_text !== ''){
            conditionText += ((conditionText !== '') ? ' and' : '') + $props.condition.creator.creator_ids_text;
        }
        if($props.condition.project.project_ids_text !== ''){
            conditionText += ((conditionText !== '') ? ' and' : '') + $props.condition.project.project_ids_text;
        }
        if((conditionText !== '')) {
            $props.condition.condition_text = ` when ${conditionText}`;
            return;
        }
        $props.condition.condition_text = ' everything';
    }

    //watchers
    watch(amountType, (newValue, oldValue) => {
        if(oldValue !== null) {
            $props.condition.amount.min_amount = null;
            $props.condition.amount.max_amount = null;
        }

    })

    watch(minAmount, (newValue, oldValue) => {

        if(newValue && ['between', 'over_or_equal_to'].includes(amountType.value)) {
            $props.condition.amount.min_amount_text = ` amount is greater than ${newValue}`;
            updateConditionText();
            return;
        }

        $props.condition.amount.min_amount_text = '';
        updateConditionText();
    })

    watch(maxAmount, (newValue, oldValue) => {
        if(newValue && ['between', 'under'].includes(amountType.value)) {
            $props.condition.amount.max_amount_text = ` amount is less than ${newValue}`;
            updateConditionText();
            return;
        }

        $props.condition.amount.max_amount_text = '';
        updateConditionText();
    })

    watch(creatorType, (newValue, oldValue) => {
        if(oldValue !== null) {
            $props.condition.creator.creator_ids = null;
        }
    })

    watch(creatorIds, (newValue, oldValue) => {
        if(newValue && newValue.length > 0 && creatorType.value !== 'any') {
            let names = getCreatorNames(newValue);

            if(creatorType.value === 'matched') {
                $props.condition.creator.creator_ids_text = ` created by ${names}`;
                updateConditionText();
                return;
            }

            $props.condition.creator.creator_ids_text = ` not created by ${names}`;
            updateConditionText();
            return;
        }
        $props.condition.creator.creator_ids_text = '';
        updateConditionText();
    })

    watch(projectType, (newValue, oldValue) => {
        if(oldValue !== null) {
            $props.condition.project.project_ids = null;
        }
    })

    watch(projectIds, (newValue, oldValue) => {
        if (newValue && newValue.length > 0 && projectType.value !== 'any') {
            let names = getProjectNames(newValue);
            if (projectType.value === 'matched') {
                $props.condition.project.project_ids_text = ` belongs to ${names} project(s)`;
                updateConditionText();
                return;
            }

            $props.condition.project.project_ids_text = ` not belongs to ${names} project(s)`;
            updateConditionText();
            return;
        }
        $props.condition.project.project_ids_text = '';
        updateConditionText();
    })

</script>

<style scoped>

</style>