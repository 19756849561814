<template>
    <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-xl mt-5">
            <div class="modal-content" style="height: 90vh">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <button
                                @click="toggleModal"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                            <h1 class="mb-0 fw-bolder">Approval matrix for the step "Approval step"</h1>
                            <button class="btn btn-sm btn-success" @click="handleSubmit">Done</button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">

                <div class="modal-body table-responsive">
                    <div class="row">
                        <div class="col-4 mb-2">
                            <div class="d-flex justify-content-between">
                                <add-step-user
                                    :step="step"
                                    :users="users"
                                />
                            </div>
                        </div>
                    </div>
                    <table class="table table-borderless bg-white">
                        <thead>
                            <tr>
                                <th colspan="2">Approver</th>
                                <th>Amount</th>
                                <th>Creator</th>
                                <th>Project</th>
                            </tr>
                        </thead>
                        <tbody>
                            <user-condition
                                v-for="(condition, index) in step.user_conditions ?? []"
                                :index="index"
                                :users="users"
                                :projects="projects"
                                :condition="condition"
                                :amountConditionTypes="amountConditionTypes"
                                :conditionTypes="conditionTypes"
                                @onClose="onClose"
                            />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {inject, onMounted, ref} from "vue";
import UserCondition from "@/components/molecule/approval-matrix/UserCondition.vue";
import AddStepUser from "@/components/molecule/approval-matrix/AddStepUser.vue";
import handleInventory from "@/services/modules/inventory";
import handleProjects from "@/services/modules/procurement/project";
import {useRoute} from "vue-router";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";

    const {fetchHome} = handleInventory();
    const {fetchProjects} = handleProjects();
    const {storeOrUpdateStep, deleteCondition} = handleWorkflow();

    const $route = useRoute();
    const $props = defineProps({
        step: Object,
        users: Object,
    });
    const showError = inject('showError');
    const isOpen = ref(false);
    const userCondition = ref(null);
    const amountConditionTypes = ref([]);
    const conditionTypes = ref([]);
    const projects = ref([]);

    const toggleModal = () => {
        isOpen.value = !isOpen.value
    }

    const handleSubmit = () => {
        let formData = JSON.parse(JSON.stringify($props.step));
        formData.company_id = $route.params.companyId;
        formData.workflow_id = $route.params.workflowId ?? $route.query.workflow_id;
        formData.user_no_condition = JSON.stringify($props.step.user_no_condition);
        formData.user_conditions = JSON.stringify($props.step.user_conditions);
        storeOrUpdateStep(formData)
            .then(res => {
                if(res.data) {
                    $props.step.id = res.data.id;
                    $props.step.user_conditions = res.data.user_conditions;
                    toggleModal();
                }
            })
            .catch(err => {
                showError('Something went wrong please try again');
            })
    }

    const onClose = (index) => {
        let conditionId = $props.step.user_conditions[index].id ?? null;
        let companyQuery = `?company_id=${$route.params.companyId}`;
        if(conditionId === null){
            $props.step.user_conditions.splice(index, 1)
            return;
        }
        deleteCondition(conditionId, companyQuery)
            .then(res => {
                if(res.status) {
                    $props.step.user_conditions.splice(index, 1)
                    return;
                }
                showError(res.message);
            })
            .catch(err => {
                showError(err.response.message);
            })

    }

    const prepareTypeOptions = (data) => {
        if(data.approval_matrix_amount_condition_types) {
            Object.keys(data.approval_matrix_amount_condition_types).forEach( val => {
                amountConditionTypes.value.push({
                    id: val,
                    name: data.approval_matrix_amount_condition_types[val]
                })
            })
        }

        if(data.approval_matrix_condition_types) {
            Object.keys(data.approval_matrix_condition_types).forEach( val => {
                conditionTypes.value.push({
                    id: val,
                    name: data.approval_matrix_condition_types[val]
                })
            })
        }
    }

    onMounted(() => {
        let query = `?company_id=${$route.params.companyId}`
        Promise.all([
            fetchHome().then( res => {
                if(res.data) {
                    prepareTypeOptions(res.data);
                }
            }),
            fetchProjects(query).then( res => {
                if(res.data) {
                    projects.value = res.data;
                }
            })
        ])
            .then( () => {})
            .catch( err => {
                if(err.response.message){
                    showError(err.response.message);
                }
            })
    });

    defineExpose({
        toggleModal,
    })
</script>