<template>
    <div class="wrapper">
        <div class="card">
            <div style="background: #DBE7F2" class="p-2">
                <TitleButton
                    btnTitle="Add New"
                    :showBtn="true"
                    :showSettingBtn="false"
                    :showAddNew="false"
                    :title="pageTitle"
                    @onClickCloseButton="onClickCloseButton"
                />

                <div class="row mt-2">
                    <h4>Basic Info</h4>
                    <hr/>
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="mb-2 row">
                            <label class="align-middle">Form Name</label>
                            <v-select
                                placeholder="Select Form"
                                v-model="formData.page_id"
                                :options="pages"
                                label="label"
                                :reduce="label => label.id"
                            />
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4">
                        <label class="align-middle">Workflow Name</label>
                        <input type="text" class="form-control" placeholder="Workflow Name" v-model="formData.name">
                    </div>

                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="mb-2 row">
                            <label class="align-middle">Select Create Pages</label>
                            <v-select
                                placeholder="Select Create Pages"
                                v-model="formData.selected_pages"
                                :options="allPages"
                                label="label"
                                :multiple="true"
                                :reduce="label => label.id"
                            />
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 col-md-4">
                        <label class="align-middle">Start Date</label>
                        <input type="date" class="form-control" v-model="formData.start_date">
                    </div>

                    <div class="col-12 col-sm-6 col-md-4">
                        <label class="align-middle">End Date</label>
                        <input type="date" class="form-control" v-model="formData.end_date">
                    </div>

                    <div class="col-12 col-sm-6 col-md-4">
                        <br>
                        <label class="form-check-label" for="send-mail">Send E-mail?</label>
                        <input class="form-check-input ms-1"
                               id="send-mail" type="checkbox"
                               v-model="formData.send_email"
                               @click="formData.send_email = !formData.send_email"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="d-flex justify-content-between">
                    <h3>Approval Steps</h3>
                    <div>
                        <button class="btn btn-sm btn-primary me-2" @click="handleSubmit(true)">Add new step</button>
                        <button class="btn btn-sm btn-success me-2" @click="handleSubmit()">Save</button>
                        <button class="btn btn-sm btn-success"
                                :disabled="formData.status === 'active'"
                                @click="handleSubmit(false, true)">
                            {{ (formData.status === 'active') ? 'Activated' :'Make Active' }}
                        </button>
                    </div>
                </div>
                <hr class="mt-1">
                <div class="row overflow-x-scroll w-auto flex-nowrap" ref="stepParent">
                    <slot v-if="formData.approval_steps.length > 0">
                        <step v-for="(step, index) in formData.approval_steps"
                              :key="index"
                              :index="index"
                              :step="step"
                              :users="users"
                              @onClose="onClose"
                        />
                    </slot>
                    <div v-else class="alert alert-danger p-4 text-center">
                        Please fill up the above from first before adding a step
                    </div>
                </div>
            </div>
        </div>

        <GlobalLoader/>
    </div>
</template>

<script setup>
import TitleButton              from '@/components/atom/TitleButton'
import {computed, inject, onMounted, ref} from "vue";
import {useRoute, useRouter}    from "vue-router";
import handleUser               from "@/services/modules/user";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";
import Step           from "@/components/molecule/approval-matrix/Step.vue";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import {useStore} from "vuex";

const {userList} = handleUser();
const {fetchPages, storeWorkflow, fetchWorkflow, deleteStep} = handleWorkflow()
const showError = inject('showError');
const showSuccess = inject('showSuccess');

const $router = useRouter();
const $route = useRoute();
const $store = useStore();
const pages = ref([]);
const allPages = ref([]);
const users = ref([]);
const stepParent = ref(null);
const pageTitle = ref('Add Workflow Matrix');
let companyId = $route.params.companyId;
let companyQuery = `?company_id=${companyId}`;

const formData = ref({
    name: null,
    page_id: null,
    start_date: null,
    end_date: null,
    approval_steps: [],
    selected_pages: [],
    send_email: true,
});

const workflowId = computed(() => {
    return $route.params.workflowId ?? $route.query.workflow_id ?? null;
})

const onClose = (index) => {
    let stepId = formData.value.approval_steps[index].id ?? null;
    let companyQuery = `?company_id=${companyId}`;
    if(stepId === null){
        formData.value.approval_steps.splice(index, 1)
        return;
    }

    deleteStep(stepId, companyQuery)
        .then(res => {
            if(res.status) {
                formData.value.approval_steps.splice(index, 1)
                return;
            }
            showError(res.message);
        })
        .catch(err => {
            showError(err.response.message);
        })

}

const onClickCloseButton = () => {
    $router.push({name: `approval-matrix`, params: $route.params, query: $route.query});
}

const addNewStep = () => {
    formData.value.approval_steps.push({
        name: "Approval Step",
        user_no_condition: {
            condition: 'all',
            number: null,
        },
        user_id: [],
        user_conditions: [],
    });
    setTimeout(() => {
        if(stepParent.value.scrollWidth > stepParent.value.clientWidth) {
            stepParent.value.scrollLeft = stepParent.value.scrollWidth;
        }
    }, 100);
}

const navigateToListPage = () => {
    $router.push({
        name: `approval-matrix`,
        params: $route.params,
        query: $route.query
    });
}

const navigateToUpdatePage = () => {
    $router.replace({name: 'approval-matrix-edit',
        params: {
            companyId: $route.params.companyId,
            moduleId: $route.params.moduleId,
            menuId: $route.params.menuId,
            pageId: $route.params.pageId,
            workflowId: $route.query.workflow_id
        },
        query: {
            start: $route.query.start,
            end:  $route.query.end
        }
    })
}

const getWorkflowDetails = () => {
    if(workflowId.value) {
        pageTitle.value = "Update Workflow Matrix";
        fetchWorkflow(companyQuery, workflowId.value).then(res => {
            if (res.data){
                formData.value = res.data;
                formData.value.send_email = res.data.send_email === 1;
                formData.value.selected_pages = JSON.parse(res.data.selected_pages);
            }
        })
    }
}

const handleSubmit = (isAddStep = false, active = false) => {
    if(isAddStep && workflowId.value){
        addNewStep();
        return;
    }

    $store.state.loading = true;

    //Prepare data
    if(!formData.value.start_date) formData.value.start_date = new Date().toISOString().split('T')[0]
    let copyFormData = JSON.parse(JSON.stringify(formData.value))

    if(workflowId.value){
        copyFormData.id = workflowId.value;
    }

    if(active) {
        copyFormData.status = 'active';
    }

    copyFormData.company_id = companyId;
    copyFormData.approval_steps = JSON.stringify(formData.value.approval_steps)
    copyFormData.selected_pages = JSON.stringify(formData.value.selected_pages)

    //API Call
    storeWorkflow(copyFormData, '')
        .then(async res => {
            if (res.status) {
                if (isAddStep) {
                    let routeQuery = Object.assign({}, $route.query)
                    routeQuery.workflow_id = res.data.id
                    await $router.replace({query: routeQuery})
                    addNewStep();
                    return;
                }
                navigateToListPage()
                return;
            }
            showError(res.message);
        })
        .catch(err => {
            showError(err)
        })
    $store.state.loading = false;
}

onMounted(() => {
    if($route.query.workflow_id){
        navigateToUpdatePage()
    }

    let allPagesQuery = companyQuery + `&get_all=1`

    $store.state.loading = true;
    formData.value.start_date = new Date().toISOString().split('T')[0];
    Promise.all([
        userList(companyId).then(res => {
            if(res.data) {
                users.value = res.data;
            }
        }),
        fetchPages(companyQuery).then(res => {
            if (res.data){
                pages.value = res.data;
            }
        }),
        fetchPages(allPagesQuery).then(res => {
            if (res.data){
                allPages.value = res.data;
            }
        }),
        getWorkflowDetails()
    ])
        .then(res => {

        })
        .catch(err => {
            showError('Something went wrong, Please try again.');
        });
    $store.state.loading = false;
})
</script>

<style>
hr {
    margin: 1rem 0;
    color: #403d4452 !important;
    background-color: currentColor;
    border: 0;
    opacity: 1;
}
</style>
