<template>
    <div class="position-relative">
        <button class="btn btn-sm btn-primary" @click="showApproverSelection(true)">Add an Approver</button>
        <div class="user-selector card" v-if="show">
            <div class="card-header d-flex justify-content-between p-1">
                <h5 class="card-title">
                    Add a user
                </h5>
                <button class="btn btn-sm btn-danger" @click="showApproverSelection()">x</button>
            </div>
            <hr class="m-0">
            <div class="card-body">
                <div class="col-12">
                    <div class="mb-2">
                        <v-select
                            placeholder="Select User"
                            v-model="userId"
                            :options="users"
                            label="user_name"
                            :reduce="user_name => user_name.id"
                        />
                    </div>
                </div>
                <button class="btn btn-sm btn-success float-end" @click="addUserIdToList()">Add</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import {inject, ref} from "vue";
import {useRoute} from "vue-router";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";

const {storeOrUpdateStep} = handleWorkflow();
const showError = inject('showError');
const $props = defineProps({
    step: {
        default: {}
    },
    users: {
        default: {}
    },
})
const $emit = defineEmits(['userIdSelected']);
const $route = useRoute();
const show = ref(false);
const userId = ref(null);

//method
const showApproverSelection = (value = false) =>{
    show.value = value;
}


const handleSubmit = () => {
    let formData = JSON.parse(JSON.stringify($props.step));
    formData.company_id = $route.params.companyId;
    formData.workflow_id = $route.params.workflowId ?? $route.query.workflow_id;
    formData.user_no_condition = JSON.stringify($props.step.user_no_condition);
    formData.user_conditions = JSON.stringify($props.step.user_conditions);
    storeOrUpdateStep(formData)
        .then(res => {
            if(res.data) {
                $props.step.id = res.data.id;
                $props.step.user_conditions = res.data.user_conditions;
            }
        })
        .catch(err => {
            showError('Something went wrong please try again');
        })
}
const addUserIdToList = async () => {
    let selectedUser = null;
    if (userId.value === null) {
        showError('Please select a user first');
        return;
    }
    if ($props.step.user_id.includes(userId.value)) {
        showError('The user is already selected');
        return;
    }
    $props.step.user_id.push(userId.value);
    selectedUser = $props.users.find((user) => {
        return user.id === userId.value;
    });
    if (selectedUser.id) {
        $props.step.user_conditions.push({
            user_id: selectedUser.id,
            user_name: selectedUser.user_name,
            condition_text: ' everything',
            amount: {
                type: 'any',
                min_amount: null,
                max_amount: null,
                min_amount_text: '',
                max_amount_text: '',
            },
            creator: {
                type: 'any',
                creator_ids: null,
                creator_ids_text: '',
            },
            project: {
                type: 'any',
                project_ids: null,
                project_ids_text: '',
            }
        })
    }
    userId.value = null;
    await handleSubmit();
    showApproverSelection();
}
</script>

<style scoped>
    .user-selector {
        width: 300px;
        height: auto;
        z-index: 11;
        border: 1px solid lightgrey;
        position: absolute;
        background: #ffffff;
        top: 0px;
        left: 105%;
    }
</style>