<template>
    <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-lg mt-5 mx-auto">
            <div class="modal-content" style="min-height: 70vh">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <button
                                @click="toggleModal"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                            <h2 class="mb-0 fw-bolder">Approval condition</h2>
                            <button class="btn btn-sm btn-success" @click="handleSubmit">Done</button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                It can be selected whether all or only one of the assigned Approvers is required to approve the request to complete this Approval step.
                            </div>
                            <div class="col-md-6 mt-3" @click="changeCondition()">
                                <div
                                    class="card border-1 border-grey min-vh"
                                    :class="userCondition === 'all' ? 'border-success' : ''"
                                >
                                    <div class="card-header justify-content-between">
                                        <h4>
                                            All
                                        </h4>
                                        <div class="checked-sign" :class="userCondition === 'all' ? 'bg-success' : ''">
                                            <i class="fa-solid fa-check"></i>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <p class="fw-bolder">If this option is chosen, all assigned Approvers will have to approve the request in this step</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mt-3" @click="changeCondition('any_of')">
                                <div
                                    class="card border-1 border-grey min-vh"
                                    :class="userCondition === 'any_of' ? 'border-success' : ''"
                                >
                                    <div class="card-header justify-content-between">
                                        <h4>
                                            Any of
                                        </h4>
                                        <div class="checked-sign" :class="userCondition === 'any_of' ? 'bg-success' : ''">
                                            <i class="fa-solid fa-check"></i>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <p class="fw-bolder">If this option is chosen, specified number of assigned Approvers will have to approve the request in this step.</p>

                                        <p> <b>Important:</b> if number of approvers for exact condition is less than set then all of approvers of this step must approve this step.</p>

                                        <div class="row">
                                            <div class="col-md-7">
                                                <p>
                                                    Number of approvers:
                                                </p>
                                            </div>
                                            <div class="col-md-5">
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    min="0"
                                                    v-model="step.user_no_condition.number"
                                                    :disabled="(userCondition === 'all')"
                                                    ref="numberOfUsers"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";
import {useRoute} from "vue-router";


    const {storeOrUpdateStep} = handleWorkflow();
    const $route = useRoute();
    const $props = defineProps({
        step: {
            type: Object,
            required: true,
        }
    })
    const isOpen = ref(false);
    const numberOfUsers = ref(false);

    const userCondition = computed(() => $props.step.user_no_condition.condition)

    const changeCondition = (condition = 'all') => {
        if(condition === 'all') {
            $props.step.user_no_condition.condition = condition;
            $props.step.user_no_condition.number = null;
            return;
        }
        $props.step.user_no_condition.condition = condition;
        $props.step.user_no_condition.number = null;
    }

    const handleSubmit = () => {
        let formData = JSON.parse(JSON.stringify($props.step));
        formData.company_id = $route.params.companyId;
        formData.workflow_id = $route.params.workflowId ?? $route.query.workflow_id;
        formData.user_no_condition = JSON.stringify($props.step.user_no_condition);
        formData.user_conditions = JSON.stringify($props.step.user_conditions);
        storeOrUpdateStep(formData)
            .then(res => {
                if(res.data) {
                    $props.step.id = res.data.id;
                    $props.step.user_conditions = res.data.user_conditions;
                    toggleModal();
                }
            })
            .catch(err => {
                showError('Something went wrong please try again');
            })
    }

    const toggleModal = () => {
        isOpen.value = !isOpen.value
    }

    defineExpose({
        toggleModal
    })
</script>

<style scoped>
    .checked-sign{
        width: 25px;
        height: 25px;
        text-align: center;
        color: white;
        border: 2px solid #1e9754;
        border-radius: 50%;
        line-height: 1.6;
    }
    .min-vh {
        min-height: 40vh;
    }
</style>