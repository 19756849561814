<template>
    <div class="col-md-4 mb-5">
        <div class="steps">
            <div class="card  border-1 border-grey m-1">
                <div class="card-header" style="background: #DBE7F2">
                    <div class="row w-100">
                        <div class="col-9">
                            <input type="text" class="form-control" v-model="step.name" @change="shouldSave = true">
                        </div>
                        <div class="col-3 d-flex justify-content-between" style="margin-top: 5px">
                            <button class="btn btn-sm btn-success text-white me-2" v-if="shouldSave" @click="handleSubmit">
                                <i class="fa-solid fa-check"></i>
                            </button>
                            <button class="btn btn-sm btn-danger" @click="$emit('onClose', index)">X</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between my-2">
                        <add-step-user
                            :step="step"
                            :users="users"
                        />
                        <div>
                            <button
                                class="btn btn-sm btn-success font-medium-3"
                                title="Define conditions for Approvars in Approval Matrix"
                                @click="openModal"
                            >
                                <i class="fa-regular fa-calendar-days"></i>
                            </button>
                        </div>
                    </div>
                    <hr />
                    <p>
                        Approval condition:
                        <b class="approval-condition" @click="openUserModal">
                            {{(userCondition === 'all') ? 'All' : `Any of ${step.user_no_condition.number ?? 0} users`}}
                            <i class="fa-solid fa-caret-down"></i>
                        </b>
                    </p>
                    <slot v-for="(user, index) in step.user_conditions ?? []">
                        <p class="fw-bolder">{{ user.user_name }}</p>
                        <p>Should Approve {{ user.condition_text }}</p>
                        <hr>
                    </slot>
                </div>
            </div>
        </div>
        <approvar-conditions-modal
            :step="step"
            :users="users"
            ref="conditionModal"
        />
        <user-count-modal
            :step="step"
            ref="userCountModal"
        />
    </div>
</template>

<script setup>
    import AddStepUser from "@/components/molecule/approval-matrix/AddStepUser.vue";
    import {computed, ref} from "vue";
    import ApprovarConditionsModal from "@/components/molecule/approval-matrix/ApprovarConditionsModal.vue";
    import UserCountModal from "@/components/molecule/approval-matrix/UserCountModal.vue";
    import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";
    import {useRoute} from "vue-router";

    const $props = defineProps({
        step: {
            default: {}
        },
        users: {
            default: {}
        },
        index: {
            type: Number,
            default: 0
        }
    })
    const $route = useRoute();
    const {storeOrUpdateStep} = handleWorkflow()
    const conditionModal = ref(null);
    const userCountModal = ref(null);
    const shouldSave = ref(false);

    const userCondition = computed(() => $props.step.user_no_condition.condition)

    const handleSubmit = () => {
        let formData = JSON.parse(JSON.stringify($props.step));
        formData.company_id = $route.params.companyId;
        formData.workflow_id = $route.params.workflowId ?? $route.query.workflow_id;
        formData.user_no_condition = JSON.stringify($props.step.user_no_condition);
        formData.user_conditions = JSON.stringify($props.step.user_conditions);
        storeOrUpdateStep(formData)
            .then(res => {
                if(res.data) {
                    $props.step.id = res.data.id;
                    $props.step.user_conditions = res.data.user_conditions;
                    shouldSave.value = false;
                }
            })
            .catch(err => {
                showError('Something went wrong please try again');
            })
    }

    //methods
    const openModal = () => {
        conditionModal.value.toggleModal();
    }
    const openUserModal = () => {
        userCountModal.value.toggleModal();
    }

</script>

<style scoped>
.approval-condition{
    cursor: pointer;
}
</style>